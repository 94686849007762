import React from 'react';

const WhyUs: React.FC = () => {
  return (
    <section id="whyus" className="py-20 bg-gray-100">
      <h2 className="text-3xl font-bold text-center mb-12">Why Us</h2>
      <div className="flex flex-wrap justify-around max-w-6xl mx-auto mb-6 p-4">
        Our app stands out by offering real-time report generation and AI-driven
        photo annotation, reducing the need for post-inspection editing. With
        smart, pre-built templates customized for each inspection, it minimizes
        manual effort and ensures reports are highly accurate and tailored to
        specific properties. The app streamlines the entire process, from data
        collection to report finalization, making it faster, more intuitive, and
        less labor-intensive. Whether you're an independent inspector or part of
        a larger team, this tool enhances efficiency, accuracy, and overall
        productivity.
      </div>
    </section>
  );
};

export default WhyUs;
