import React from 'react';

const Comparison: React.FC = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold text-center my-12">
        Our Product vs. Other Products
      </h3>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="text-left px-6 py-4 font-bold text-gray-600 ">
              Feature
            </th>
            <th className="text-left px-6 py-4 font-bold text-gray-600">
              Our Product
            </th>
            <th className="text-left px-6 py-4 font-bold text-gray-600">
              Other Products
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b">
            <td className="px-6 py-4 text-gray-700">Report Generation</td>
            <td className="px-6 py-4 text-gray-700">
              Inspection reports are automatically generated in{' '}
              <span className="font-bold">real-time</span>
            </td>
            <td className="px-6 py-4 text-gray-700">
              Allow inspectors to take notes and capture photos on-site but
              complete the report afterward
            </td>
          </tr>
          <tr className="bg-gray-50 border-b">
            <td className="px-6 py-4 text-gray-700">Core Function</td>
            <td className="px-6 py-4 text-gray-700">
              <span className="font-bold">AI assistance</span>: Use AI in
              generating recommendations, causes, and building code references
            </td>
            <td className="px-6 py-4 text-gray-700">
              Only structured notebook with no AI to assist in automating or
              improving the reporting process
            </td>
          </tr>
          <tr className="border-b">
            <td className="px-6 py-4 text-gray-700">Platform</td>
            <td className="px-6 py-4 text-gray-700">
              <span className="font-bold">Mobile APP</span> & PC
            </td>
            <td className="px-6 py-4 text-gray-700">
              Complete the report on PC only
            </td>
          </tr>
          <tr className="bg-gray-50 border-b">
            <td className="px-6 py-4 text-gray-700">Workflow</td>
            <td className="px-6 py-4 text-gray-700">
              Minimize repetitive tasks and data transfer
            </td>
            <td className="px-6 py-4 text-gray-700">
              Involves transferring data from mobile device to desktop, but
              still needs to finalize the report on desktop
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Comparison;
