import React from 'react';
import Comparison from './Comparison';

const Features: React.FC = () => {
  return (
    <section id="features" className="py-20 bg-white">
      <h2 className="text-3xl font-bold text-center mb-12">Our Features</h2>
      <div className="flex flex-wrap justify-around max-w-6xl mx-auto mb-6 p-4">
        Our app uses AI and natural language processing (NLP) to simplify report
        generation for inspectors. By automating data entry and leveraging
        real-time inputs, it significantly reduces time spent on manual tasks,
        streamlining the entire inspection process for faster, more accurate
        reporting.
      </div>
      <div className="flex flex-wrap justify-around max-w-6xl mx-auto">
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 text-center">
              AI-Assisted Report Generation
            </h3>
            <p>
              The app utilizes AI and NLP to help inspectors generate detailed,
              customized reports from their notes, photos, and on-site data,
              saving time and improving accuracy.
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 text-center">
              Real-Time Report Building
            </h3>
            <p>
              Inspectors can build reports in real-time during inspections,
              ensuring most of the report is completed by the end of the site
              visit, minimizing the need for post-inspection edits.
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 text-center">
              Voice-to-Text
            </h3>
            <p>
              With integrated voice commands, inspectors can quickly and
              accurately take notes on-site, improving efficiency and
              eliminating the need for manual data entry.
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 text-center">
              Photo Annotation with AI
            </h3>
            <p>
              The app enables inspectors to annotate photos and automatically
              detect common property issues (e.g., cracks, water damage),
              reducing manual work and improving precision.
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2 text-center">
              Smart, Pre-Built Templates
            </h3>
            <p>
              AI-driven customization of report templates based on property type
              and previous inspections ensures a more intuitive,
              situation-specific reporting process.
            </p>
          </div>
        </div>
        <Comparison />
      </div>
    </section>
  );
};

export default Features;
