import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-gray-100 py-4">
      <nav>
        <ul className="flex justify-center space-x-6">
          <li>
            <a href="#home" className="text-gray-700 hover:text-gray-900">
              Home
            </a>
          </li>
          <li>
            <a href="#whyus" className="text-gray-700 hover:text-gray-900">
              Why Us
            </a>
          </li>
          <li>
            <a href="#features" className="text-gray-700 hover:text-gray-900">
              Features
            </a>
          </li>
          <li>
            <a href="#pricing" className="text-gray-700 hover:text-gray-900">
              Pricing
            </a>
          </li>
          <li>
            <a
              href="mailto:help@morph-technologies.com"
              className="text-gray-700 hover:text-gray-900"
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
