import React from 'react';

const Pricing: React.FC = () => {
  return (
    <section id="pricing" className="py-20 bg-gray-100">
      <h2 className="text-3xl font-bold text-center mb-12">Pricing Plans</h2>
      <div className="flex justify-center items-stretch gap-8">
        <div className="bg-white p-8 rounded-lg shadow-md text-center w-full max-w-sm flex flex-col justify-between">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Basic</h3>
            <p className="text-4xl font-bold mb-6">
              $9.99<span className="text-sm font-normal">/month</span>
            </p>
            <ul className="mb-8 space-y-2">
              <li>AI-assisted report generation</li>
              <li>Real-time report building</li>
              <li>Voice-to-text functionality</li>
              <li>Photo annotation features</li>
              <li>Usage limit: 10 reports/month</li>
            </ul>
          </div>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full">
            Choose Plan
          </button>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-md text-center w-full max-w-sm flex flex-col justify-between">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Premium</h3>
            <p className="text-4xl font-bold mb-6">
              $19.99<span className="text-sm font-normal">/month</span>
            </p>
            <ul className="mb-8 space-y-2">
              <li>All Basic features</li>
              <li>Unlimited report usage</li>
            </ul>
          </div>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full">
            Choose Plan
          </button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
