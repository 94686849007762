import React from 'react';

const Hero: React.FC = () => {
  return (
    <section
      id="home"
      className="flex items-center text-center justify-around py-12 max-w-7xl mx-auto p-4"
    >
      <div className="bg-hero-image bg-cover bg-center h-[50vh] w-2/3" />
      <div className="p-4">
        <h1 className="text-4xl font-bold mb-4">
          Welcome to Morph Technologies
        </h1>
        <p className="text-xl mb-8">
          Experience seamless inspections with AI-driven, accurate reporting
        </p>
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Get Started
        </button>
      </div>
    </section>
  );
};

export default Hero;
