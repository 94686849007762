import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-6xl mx-auto px-4 flex flex-wrap justify-between items-center">
        <p>&copy; 2024 Morph Technologies. All rights reserved.</p>
        <ul className="flex space-x-4 mt-4 sm:mt-0">
          <li>
            <a
              href="mailto:help@morph-technologies.com"
              className="hover:text-gray-300"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
